import * as React from "react";
import language from "browser-locale";

export default () => {
  if (typeof window !== "undefined") {
    const lang = language().toString();
    const { hash } = window.location;
    if (
      !hash.startsWith("#invite_token") &&
      !hash.startsWith("#email_change_token") &&
      !hash.startsWith("#recovery_token") &&
      !hash.startsWith("#confirmation_token")
    ) {
      if (lang.startsWith("de")) {
        window.location.href = "/de/";
      } else {
        window.location.href = "/en/";
      }
    }
  }

  return <div />;
};
